<template>
  <div v-if="rendered">
    <div v-if="currentRouteName === 'Profile'">
      <h2
        class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-10 text-shiftlydarkblue"
      >
        Welcome {{ this.fullUserName }}
      </h2>
      <h3
        v-if="!this.togglePremium"
        class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
      >
        Please choose one of our service offerings from the options below:
      </h3>
      <h3
        v-else
        class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
      >
        Please select which option you'd like and complete the declaration:
      </h3>
      <div class="py-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
          <div
            v-if="!this.togglePremium"
            class="lg:flex flex-wrap lg:flex-row-reverse py-5 justify-center"
            style="cursor: auto"
          >
            <div
              class="relative py-6 border-2 border-gray-200 rounded-lg h-auto lg:w-5/12 m-4"
            >
              <div class="absolute inset-x-0 -top-4">
                <span
                  class="h-8 w-32 p-1.5 bg-shiftlyorange text-white font-bold text-sm rounded-md justify-center"
                >
                  Most popular
                </span>
              </div>
              <h2 class="font-bold text-xl mb-4 text-shiftlydarkblue">
                Premium
              </h2>
              <p class="mb-6 px-2">
                Fully automated and managed by us, requiring no action from you.
              </p>
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  0.20% forex conversion fee, reducible to 0% through our
                  referral program
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Lowest fee in the industry!"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div
                v-if="this.$creditEnabled == 'true'"
                class="grid grid-cols-5 gap-2 content-around my-3"
              >
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  No minimum trade size if using our credit facility
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="R500,000 minimum trade size applies if not making use of our credit facility"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <div v-else class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  R200,000 minimum trade size
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="You need at least R200,000 of your own funds to trade with"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Shiftly arbitrage service
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Our 5 year proven arbitrage bot will execute your arbitrage trades and lock in the highest possible profit for you"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Eligible for referral program
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="We'll reduce your forex conversion fee by 0.05% for each client you refer that trades with our Premium service"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Automated trade booking
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="We'll book your trades on your behalf up to the amount of foreign capital allowances you'd like to utilise"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  SARS AIT (FIA) applications submitted on your behalf
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="We’ll submit your SARS AIT applications and follow up with SARS on your behalf"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div
                v-if="this.$creditEnabled == 'true'"
                class="grid grid-cols-5 gap-2 content-around my-3"
              >
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Optional credit facility to increase your trade size
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Increased trade size results in more profit and reduces the number of trades to reach your allowances, which results in fewer SWIFT fees payable"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="mb-7 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="flex space-x-2 justify-center">
                <button
                  type="button"
                  @click="this.togglePremium = !this.togglePremium"
                  class="w-28 py-4 px-5 mt-2.5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Select
                </button>
              </div>
            </div>

            <div
              class="lg:relative py-6 border-2 border-gray-200 rounded-lg h-auto lg:w-5/12 lg:m-4 mx-4 mb-4 mt-8"
            >
              <h2 class="font-bold text-xl mb-4 text-shiftlydarkblue">
                Standard
              </h2>
              <p class="mb-6 px-2">
                Manually book your trades and perform your own AIT applications.
              </p>
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  0.20% forex conversion fee, reducible to 0% through our
                  referral program
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Lowest fee in the industry!"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  R200,000 minimum trade size
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="You need at least R200,000 of your own funds to trade with"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Shiftly arbitrage service
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Our 5 year proven arbitrage bot will execute your arbitrage trades and lock in the highest possible profit for you"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-check text-green-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left">
                  Eligible for referral program
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="We'll reduce your forex conversion fee by 0.05% for each client you refer that trades with our Premium service"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-xmark text-red-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left opacity-50">
                  Automated trade booking
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="You'll have to book your own trades via our client portal"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="grid grid-cols-5 gap-2 content-around my-3">
                <i class="fa-solid fa-xmark text-red-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left opacity-50">
                  SARS AIT (FIA) applications submitted on your behalf
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="You'll have to submit your own SARS AIT applications "
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div
                v-if="this.$creditEnabled == 'true'"
                class="grid grid-cols-5 gap-2 content-around my-3"
              >
                <i class="fa-solid fa-xmark text-red-500 mt-1"></i>
                <p class="text-shiftlyblue col-span-3 text-left opacity-50">
                  Optional credit facility to increase your trade size
                </p>
                <span class="mb-1">
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Credit facility not available on our Standard service"
                    class="text-xs font-bold"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                  ></VuePopper>
                </span>
              </div>
              <hr
                class="mb-7 lg:mb-2 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="lg:absolute lg:inset-x-0 lg:bottom-1">
                <div class="flex space-x-2 justify-center">
                  <button
                    v-if="this.client.service_level != 'standard'"
                    :disabled="isLoading"
                    type="button"
                    class="w-28 py-4 mt-2.5 lg:mt-0 lg:my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                    :class="[isLoading ? 'px-2' : 'px-8']"
                    @click.prevent="confirmSubmissionStandard"
                  >
                    <span v-if="isLoading">
                      <i class="fa-solid fa-spinner animate-spin"></i>
                      Selecting
                    </span>
                    <span v-else>Select</span>
                  </button>
                  <button
                    v-else
                    :disabled="
                      isLoading || this.client.service_level == 'standard'
                    "
                    type="button"
                    class="w-28 py-4 mt-2.5 lg:mt-0 lg:my-5 bg-shiftlydarkblue text-white rounded-md cursor-not-allowed"
                  >
                    Selected
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="this.togglePremium"
            class="flex flex-wrap py-5 justify-center"
            style="cursor: auto"
          >
            <div
              class="relative p-6 border-2 border-gray-200 rounded-lg h-auto w-11/12 m-4"
            >
              <div class="absolute inset-x-0 -top-4">
                <span
                  class="h-8 w-32 p-1.5 bg-shiftlyorange text-white font-bold text-sm rounded-md justify-center"
                >
                  Most popular
                </span>
              </div>
              <h2 class="font-bold text-xl mb-6 text-shiftlydarkblue">
                Premium
              </h2>
              <div class="text-left relative p-4">
                <span class="text-shiftlyblue mb-2 text-md"
                  >I Would Like To</span
                >
                <div class="mb-2">
                  <div class="form-check my-1">
                    <div class="flex">
                      <input
                        class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlyblue checked:bg-shiftlyblue checked:border-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        :name="clientServiceLevel.credit_choice"
                        id="own"
                        value="own"
                        v-model="clientServiceLevel.credit_choice"
                      />
                      <label
                        v-if="this.$creditEnabled == 'true'"
                        class="form-check-label inline-block text-shiftlydarkblue"
                        for="own"
                        >Use my own funds (minimum R500k) and will not require
                        your credit facility.</label
                      >
                      <label
                        v-else
                        class="form-check-label inline-block text-shiftlydarkblue"
                        for="own"
                        >Use my own funds (minimum R200k).</label
                      >
                    </div>
                  </div>
                  <div
                    v-if="this.$creditEnabled == 'true'"
                    class="form-check my-1"
                  >
                    <div class="flex">
                      <input
                        class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlyblue checked:bg-shiftlyblue checked:border-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        :name="clientServiceLevel.credit_choice"
                        id="top_up"
                        value="top_up"
                        v-model="clientServiceLevel.credit_choice"
                      />
                      <label
                        class="form-check-label inline-block text-shiftlydarkblue"
                        for="top_up"
                        >Use some of my own funds (minimum R100k) and supplement
                        them with your credit facility.</label
                      >
                    </div>
                  </div>
                  <div
                    v-if="this.$creditEnabled == 'true'"
                    class="form-check my-1"
                  >
                    <div class="flex">
                      <input
                        class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlyblue checked:bg-shiftlyblue checked:border-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        :name="clientServiceLevel.credit_choice"
                        id="credit"
                        value="credit"
                        v-model="clientServiceLevel.credit_choice"
                      />
                      <label
                        class="form-check-label inline-block text-shiftlydarkblue"
                        for="credit"
                        >Only use funds from your credit facility.</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="mb-2.5"
                  v-if="clientServiceLevel.credit_choice !== ''"
                >
                  <span class="text-shiftlyblue text-md">Declaration</span>
                  <div
                    class="form-check my-1"
                    v-if="clientServiceLevel.credit_choice !== 'credit'"
                  >
                    <div class="flex">
                      <input
                        class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        id="leaveCapital"
                        v-model="confirmation.leaveCapital"
                      />
                      <label
                        class="form-check-label text-shiftlydarkblue"
                        for="leaveCapital"
                      >
                        I agree that, in order for Shiftly to book my trades and
                        apply for TCS PINs, I'll leave my trading capital in my
                        trading account until my trading has completed for the
                        calendar year.
                      </label>
                    </div>
                  </div>
                  <div
                    class="form-check my-1"
                    v-if="clientServiceLevel.credit_choice !== 'own'"
                  >
                    <div class="flex">
                      <input
                        class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        id="creditCheck"
                        v-model="confirmation.creditCheck"
                      />
                      <label
                        class="form-check-label text-shiftlydarkblue"
                        for="creditCheck"
                      >
                        I consent to Shiftly Credit performing a credit check on
                        me.
                      </label>
                    </div>
                  </div>
                  <div
                    class="form-check my-1"
                    v-if="clientServiceLevel.credit_choice !== 'own'"
                  >
                    <div class="flex">
                      <input
                        class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        id="clearCredit"
                        v-model="confirmation.clearCredit"
                      />
                      <label
                        class="form-check-label text-shiftlydarkblue"
                        for="clearCredit"
                      >
                        I confirm that I have a clear credit record and my
                        monthly discretionary income is greater than R7000.
                      </label>
                    </div>
                  </div>
                  <div
                    class="form-check my-1"
                    v-if="clientServiceLevel.credit_choice !== 'own'"
                  >
                    <div class="flex">
                      <input
                        class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        id="creditConfirmation"
                        v-model="confirmation.creditConfirmation"
                      />
                      <label
                        class="form-check-label text-shiftlydarkblue"
                        for="creditConfirmation"
                      >
                        I confirm that I'm not subject to an administration
                        order or have applied to be placed under administration,
                        I'm not currently or in the process of being
                        sequestrated, and I'm not under debt review or have
                        applied to be placed under debt review.
                      </label>
                    </div>
                  </div>
                  <div class="form-check my-1">
                    <div class="flex">
                      <input
                        class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                        type="checkbox"
                        id="taxProfile"
                        v-model="confirmation.taxProfile"
                      />
                      <label
                        class="form-check-label text-shiftlydarkblue"
                        for="taxProfile"
                      >
                        I confirm that I have access to my SARS eFiling profile,
                        my tax returns are up to date, and I am fully
                        tax-compliant.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-red-500 text-xs italic pb-5">
                {{ confirmationError }}
              </div>
              <div
                v-if="!this.togglePremium"
                class="flex space-x-2 justify-center"
              >
                <button
                  type="button"
                  @click="this.togglePremium = !this.togglePremium"
                  class="w-28 py-4 px-5 mt-2.5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Select
                </button>
              </div>
              <div v-else class="mx-3 flex">
                <div class="w-6/12">
                  <button
                    :disabled="isLoading"
                    @click="this.togglePremium = !this.togglePremium"
                    type="button"
                    class="w-28 py-4 px-8 mb-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  >
                    Back
                  </button>
                </div>
                <div class="w-6/12">
                  <button
                    :disabled="isLoading"
                    type="button"
                    class="w-28 py-4 mb-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                    :class="[isLoading ? 'px-1' : 'px-8']"
                    @click.prevent="confirmSubmissionPremium"
                  >
                    <span v-if="isLoading">
                      <i class="fa-solid fa-spinner animate-spin"></i>
                      Submitting
                    </span>
                    <span v-else>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "ClientProfile",
  data() {
    return {
      rendered: false,
      fullUserName: localStorage.getItem("fullUserName"),
      shortUserName: localStorage.getItem("shortUserName"),
      client: [],
      portal_state: {},
      togglePremium: false,
      clientServiceLevel: {
        service_level: "",
        credit_choice: "",
        credit_provider: "",
      },
      confirmation: {
        leaveCapital: false,
        creditCheck: false,
        clearCredit: false,
        creditConfirmation: false,
        taxProfile: false,
      },
      confirmationError: "",
      patchUrl: "",
      currentDate: new Date(),
      tradeInfo: {
        trading_status: "",
      },
      disableField: false,
      isLoading: false,
    };
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client !== "") {
        if (this.$creditEnabled == "false") {
          this.clientServiceLevel.credit_choice = "own";
        }
        if (
          this.$route.name === "Profile" &&
          this.client.service_level === "premium"
        ) {
          this.$router.push("/home");
        } else {
          this.clientServiceLevel.service_level = this.client.service_level;
          if (this.clientServiceLevel.service_level === "premium") {
            this.clientServiceLevel.credit_choice = this.client.credit_choice;
          }

          var currentDateYear = this.currentDate.getFullYear();
          ClientService.getTradeParameters().then((tradeparameters) => {
            if (tradeparameters.data.count > 0) {
              Object.entries(tradeparameters.data.results).forEach(
                ([, value]) => {
                  if (value.year === currentDateYear) {
                    this.patchUrl = value.url;
                  }
                }
              );
            }
            this.rendered = true;
          });
        }
      }
    });
  },
  created() {
    document.title = "Shiftly Client Portal - Profile";
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useApiStore, [
      "patchClientServiceLevel",
      "patchTradeParameterInformation",
    ]),
    showSuccess() {
      this.$swal(
        "Success!",
        "Your Service Level has been selected successfully.",
        "success"
      );
    },
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    confirmSubmissionPremium() {
      if (this.clientServiceLevel.credit_choice === "") {
        this.confirmationError = "Please select one of the choices.";
      } else {
        let allDeclarationsAccepted = false;
        if (this.clientServiceLevel.credit_choice === "own") {
          allDeclarationsAccepted =
            this.confirmation.leaveCapital && this.confirmation.taxProfile;
        } else if (this.clientServiceLevel.credit_choice === "top_up") {
          allDeclarationsAccepted =
            this.confirmation.leaveCapital &&
            this.confirmation.creditCheck &&
            this.confirmation.clearCredit &&
            this.confirmation.creditConfirmation &&
            this.confirmation.taxProfile;
        } else if (this.clientServiceLevel.credit_choice === "credit") {
          allDeclarationsAccepted =
            this.confirmation.creditCheck &&
            this.confirmation.clearCredit &&
            this.confirmation.creditConfirmation &&
            this.confirmation.taxProfile;
        } else {
          this.confirmationError = "Invalid credit choice.";
        }

        if (allDeclarationsAccepted) {
          this.confirmationError = "";
          this.isLoading = true;

          //set client service_level
          this.clientServiceLevel.service_level = "premium";

          //check if credit_choice = credit and set own funds to 0 and credit_amount to null
          if (this.clientServiceLevel.credit_choice === "credit") {
            this.clientServiceLevel.own_funds = 0;
            this.clientServiceLevel.credit_amount = null;
          }

          //check if credit_choice != own and set credit_provider to block_loan
          if (this.clientServiceLevel.credit_choice !== "own") {
            this.clientServiceLevel.credit_provider = "block_loan";
          } else {
            this.clientServiceLevel.credit_provider = "";
          }

          //set portal state
          this.portal_state.contact_details_show = true;
          this.portal_state.fica_documents_show = true;
          this.portal_state.source_of_funds_show = true;

          //set client status
          this.clientServiceLevel.status = "registered";

          //patch client service_level
          this.patchServiceLevelToApi();
        } else {
          this.confirmationError =
            "We can only offer our Premium service if you accept all the declarations.";
        }
      }
    },
    confirmSubmissionStandard() {
      this.confirmationError = "";
      this.isLoading = true;

      //set portal state
      this.portal_state.contact_details_show = true;
      this.portal_state.fica_documents_show = true;
      this.portal_state.source_of_funds_show = true;

      //set client status
      this.clientServiceLevel.status = "registered";

      //patch client service_level
      this.clientServiceLevel.service_level = "standard";
      this.clientServiceLevel.credit_choice = "own";
      this.clientServiceLevel.credit_provider = "";
      this.patchServiceLevelToApi();
    },
    patchServiceLevelToApi() {
      //adding objects to payload
      this.clientServiceLevel.portal_state = this.portal_state;
      //Sending payload to the backend
      var payload = { url: this.client.url, values: this.clientServiceLevel };
      this.patchClientServiceLevel(payload).then(
        () => {
          this.client.service_level = this.clientServiceLevel.service_level;
          TokenService.setServiceLevel(this.clientServiceLevel.service_level);
          EventBus.dispatch("updateServiceLevel");
          if (this.clientServiceLevel.service_level === "premium") {
            TokenService.setClientStatus(this.clientServiceLevel.status);
            EventBus.dispatch("updateClientStatus");
            TokenService.setCreditChoice(this.clientServiceLevel.credit_choice);
            EventBus.dispatch("updateCreditChoice");

            if (this.patchUrl !== "") {
              this.patchTradeParameterInfoToAPI();
            } else {
              this.isLoading = false;
              this.showSuccess();
              this.$router.push("/home");
            }
          } else {
            TokenService.removeCreditChoice();
            EventBus.dispatch("updateCreditChoice");

            this.isLoading = false;
            this.showSuccess();
            this.$router.push("/home");
          }
        },
        (error) => {
          if (error.response.status == "400") {
            this.isLoading = false;
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              this.isLoading = false;
              EventBus.dispatch("logout");
            }
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
    patchTradeParameterInfoToAPI() {
      //setting trading_status
      this.tradeInfo.trading_status = "inactive_awaiting_commencement";

      //Sending payload to the backend
      var payload = { url: this.patchUrl, values: this.tradeInfo };

      this.patchTradeParameterInformation(payload).then(
        () => {
          this.isLoading = false;
          this.showSuccess();
          this.$router.push("/home");
        },
        (error) => {
          if (error.response.status == "400") {
            this.isLoading = false;
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              this.isLoading = false;
              EventBus.dispatch("logout");
            }
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #fdb71a;
  opacity: 1;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fdb71a;
}
</style>
