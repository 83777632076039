import api from "./api";
class DataService {
  getBanks() {
    return api.get("/v1/shiftly/banks/");
  }

  getCountries() {
    return api.get("/v1/shiftly/countries/");
  }

  getEmploymentStatuses() {
    return api.get("/v1/clients/choices/employmentstatus/");
  }

  getNationalities() {
    return api.get("/v1/shiftly/nationalities/");
  }

  getHolidays() {
    return api.get("/v1/shiftly/holidays/");
  }

  getDocumentCategories() {
    return api.get("/v1/clients/choices/documentcategory/");
  }

  getProofOfAddressTypes() {
    return api.get("/v1/clients/choices/proofofaddress/");
  }

  getProofOfIdentityTypes() {
    return api.get("/v1/clients/choices/proofofidentity/");
  }

  getRaces() {
    return api.get("/v1/shiftly/choices/race/");
  }

  getSaProvinces() {
    return api.get("/v1/shiftly/choices/saprovince/");
  }

  getSourceOfFundsTypes() {
    return api.get("/v1/clients/choices/sourceoffunds/");
  }

  getTitles() {
    return api.get("/v1/shiftly/choices/title/");
  }

  getMaritalStatuses() {
    return api.get("/v1/clients/choices/maritalstatus/");
  }

  getNetWorths() {
    return api.get("/v1/clients/choices/networth/");
  }
}
export default new DataService();
