import { defineStore } from "pinia";
import axiosInstance from "../services/api";
import TokenService from "../services/token.service";
import DataService from "../services/data.service";

const token = TokenService.getToken();

const initialState = token
  ? { loggedIn: true, token }
  : { loggedIn: false, token: null };

export const useApiStore = defineStore("apiStore", {
  state: () => ({
    loggedIn: initialState.loggedIn,
    token: initialState.token,
    personalInformation: null,
    contactDetails: null,
    ficaDocuments: null,
    employmentInformation: null,
    bankDetails: null,
    sourceofFunds: null,
    eFilingInformation: null,
    allowanceInformation: null,
    localAssetsAndLiabilities: null,
    foreignAssetsAndLiabilities: null,
    incomeAndExpenses: null,
    countries: [],
    nationalities: [],
    banks: [],
    holidays: [],
    titles: [],
    races: [],
    maritalstatuses: [],
    networths: [],
    provinces: [],
    poatypes: [],
    empstatuses: [],
    softypes: [],
  }),
  actions: {
    register(registerValues) {
      return axiosInstance.post("/auth/users/", registerValues).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    },
    registerConfirm(registerConfirmValues) {
      return axiosInstance
        .post("/auth/users/activation/", registerConfirmValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    referralConfirm(referralConfirmValues) {
      return axiosInstance
        .post(
          "/v1/clients/clients/" + referralConfirmValues.cid + "/refer/",
          referralConfirmValues.values
        )
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    login(loginValues) {
      return axiosInstance.post("/auth/jwt/create/", loginValues).then(
        (response) => {
          if (response.data.refresh && response.data.access) {
            TokenService.setToken(response.data);
          }
          this.loggedIn = true;
          this.token = response.data;
          return Promise.resolve(response.data);
        },
        (error) => {
          this.loggedIn = false;
          this.token = null;
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    },
    logout() {
      TokenService.removeToken();
      TokenService.removeClientUrl();
      TokenService.removeUserName();
      TokenService.removeClientStatus();
      TokenService.removeServiceLevel();
      TokenService.removeCreditChoice();
      TokenService.removeReferralActive();
      this.loggedIn = false;
      this.token = null;
    },
    resetPassword(resetPasswordValues) {
      return axiosInstance
        .post("/auth/users/reset_password/", resetPasswordValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    resetPasswordConfirm(resetPasswordConfirmValues) {
      return axiosInstance
        .post("/auth/users/reset_password_confirm/", resetPasswordConfirmValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    changePassword(changePasswordValues) {
      return axiosInstance
        .post("/auth/users/set_password/", changePasswordValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchPersonalInformation(personalInformationValues) {
      return axiosInstance
        .patch(personalInformationValues.url, personalInformationValues.values)
        .then(
          (response) => {
            this.personalInformation = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.personalInformation = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchContactDetails(contactDetailsValues) {
      return axiosInstance
        .patch(contactDetailsValues.url, contactDetailsValues.values)
        .then(
          (response) => {
            this.contactDetails = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.contactDetails = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postFicaDocuments(ficaDocumentsValues) {
      return axiosInstance
        .post("/v1/clients/documents/", ficaDocumentsValues)
        .then(
          (response) => {
            this.ficaDocuments = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.ficaDocuments = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postEmploymentInformation(employmentInformationValues) {
      return axiosInstance
        .post("/v1/clients/documents/", employmentInformationValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchEmploymentInformation(employmentInformationValues) {
      return axiosInstance
        .patch(
          employmentInformationValues.url,
          employmentInformationValues.values
        )
        .then(
          (response) => {
            this.employmentInformation = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.employmentInformation = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postBankDetails(bankDetailsValues) {
      return axiosInstance
        .post("/v1/clients/bankaccounts/", bankDetailsValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchBankDetails(bankDetailsValues) {
      return axiosInstance
        .patch(bankDetailsValues.url, bankDetailsValues.values)
        .then(
          (response) => {
            this.bankDetails = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.bankDetails = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postSourceofFunds(sourceofFundsValues) {
      return axiosInstance
        .post("/v1/clients/documents/", sourceofFundsValues)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchSourceofFunds(sourceofFundsValues) {
      return axiosInstance
        .patch(sourceofFundsValues.url, sourceofFundsValues.values)
        .then(
          (response) => {
            this.sourceofFunds = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.sourceofFunds = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patcheFilingInformation(eFilingInformationValues) {
      return axiosInstance
        .patch(eFilingInformationValues.url, eFilingInformationValues.values)
        .then(
          (response) => {
            this.eFilingInformation = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.eFilingInformation = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postAllowanceInformation(allowanceInformationValues) {
      return axiosInstance
        .post(
          "/v1/clients/foreigncapitalallowances/",
          allowanceInformationValues.values
        )
        .then(
          (response) => {
            this.allowanceInformation = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.allowanceInformation = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchAllowanceInformation(allowanceInformationValues) {
      return axiosInstance
        .patch(
          allowanceInformationValues.url,
          allowanceInformationValues.values
        )
        .then(
          (response) => {
            this.allowanceInformation = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.allowanceInformation = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postLocalAssetsAndLiabilities(localAssetsAndLiabilitiesValues) {
      return axiosInstance
        .post(
          "/v1/clients/assetsliabilities/",
          localAssetsAndLiabilitiesValues.values
        )
        .then(
          (response) => {
            this.localAssetsAndLiabilities = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.localAssetsAndLiabilities = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchLocalAssetsAndLiabilities(localAssetsAndLiabilitiesValues) {
      return axiosInstance
        .patch(
          localAssetsAndLiabilitiesValues.url,
          localAssetsAndLiabilitiesValues.values
        )
        .then(
          (response) => {
            this.localAssetsAndLiabilities = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.localAssetsAndLiabilities = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postForeignAssetsAndLiabilities(foreignAssetsAndLiabilitiesValues) {
      return axiosInstance
        .post(
          "/v1/clients/assetsliabilities/",
          foreignAssetsAndLiabilitiesValues.values
        )
        .then(
          (response) => {
            this.foreignAssetsAndLiabilities = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.foreignAssetsAndLiabilities = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchForeignAssetsAndLiabilities(foreignAssetsAndLiabilitiesValues) {
      return axiosInstance
        .patch(
          foreignAssetsAndLiabilitiesValues.url,
          foreignAssetsAndLiabilitiesValues.values
        )
        .then(
          (response) => {
            this.foreignAssetsAndLiabilities = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.foreignAssetsAndLiabilities = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postIncomeAndExpenses(incomeAndExpensesValues) {
      return axiosInstance
        .post("/v1/clients/incomeexpenses/", incomeAndExpensesValues.values)
        .then(
          (response) => {
            this.incomeAndExpenses = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.incomeAndExpenses = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchIncomeAndExpenses(incomeAndExpensesValues) {
      return axiosInstance
        .patch(incomeAndExpensesValues.url, incomeAndExpensesValues.values)
        .then(
          (response) => {
            this.incomeAndExpenses = true;
            return Promise.resolve(response.data);
          },
          (error) => {
            this.incomeAndExpenses = false;
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchClientStatus(clientStatusValues) {
      return axiosInstance
        .patch(clientStatusValues.url, clientStatusValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchClientServiceLevel(clientServiceLevelValues) {
      return axiosInstance
        .patch(clientServiceLevelValues.url, clientServiceLevelValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchPortalStatus(portalStatusValues) {
      return axiosInstance
        .patch(portalStatusValues.url, portalStatusValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postTCSPin(tcsPinValues) {
      return axiosInstance.post("/v1/clients/tcspins/", tcsPinValues).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    },
    postTradeRequest(tradeRequestValues) {
      return axiosInstance
        .post("/v1/trades/traderequests/", tradeRequestValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postArbitrageTradeRequest(tradeRequestValues) {
      return axiosInstance
        .post("/v1/arbitrage/trades/", tradeRequestValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postWithdrawRequest(withdrawRequestValues) {
      return axiosInstance
        .post("/v1/trades/withdrawrequests/", withdrawRequestValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postArbitrageWithdrawRequest(withdrawRequestValues) {
      return axiosInstance
        .post("/v1/arbitrage/withdrawals/", withdrawRequestValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postTransactionHistoryRequest(transactionHistoryRequestValues) {
      return axiosInstance
        .post(
          "/v1/trades/txhistoryrequests/",
          transactionHistoryRequestValues.values
        )
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postArbitrageTransactionHistoryRequest(transactionHistoryRequestValues) {
      return axiosInstance
        .post(
          "/v1/arbitrage/txhistories/",
          transactionHistoryRequestValues.values
        )
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postReferralInvite(referralInviteValues) {
      return axiosInstance
        .post("/v1/clients/clients/invite/", referralInviteValues.values)
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    postZohoSignRequest(client_url) {
      return axiosInstance.post(client_url + "zohosign_request/").then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    },
    postTradeParameterInformation(tradeParameterInformationValues) {
      return axiosInstance
        .post(
          "/v1/clients/tradeparameters/",
          tradeParameterInformationValues.values
        )
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    patchTradeParameterInformation(tradeParameterInformationValues) {
      return axiosInstance
        .patch(
          tradeParameterInformationValues.url,
          tradeParameterInformationValues.values
        )
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            console.log(error.response);
            return Promise.reject(error);
          }
        );
    },
    refreshToken(accessToken) {
      this.loggedIn = true;
      this.token = { ...this.token, access: accessToken.access };
    },
    setCountries() {
      DataService.getCountries().then((response) => {
        this.countries = response.data;
      });
    },
    setNationalities() {
      DataService.getNationalities().then((response) => {
        this.nationalities = response.data;
      });
    },
    setBanks() {
      DataService.getBanks().then((response) => {
        this.banks = response.data;
      });
    },
    setHolidays() {
      DataService.getHolidays().then((response) => {
        this.holidays = response.data;
      });
    },
    setTitles() {
      DataService.getTitles().then((response) => {
        this.titles = response.data;
      });
    },
    setRaces() {
      DataService.getRaces().then((response) => {
        this.races = response.data;
      });
    },
    setMaritalStatuses() {
      DataService.getMaritalStatuses().then((response) => {
        this.maritalstatuses = response.data;
      });
    },
    setNetWorths() {
      DataService.getNetWorths().then((response) => {
        this.networths = response.data;
      });
    },
    setProvinces() {
      DataService.getSaProvinces().then((response) => {
        this.provinces = response.data;
      });
    },
    setProofOfAddressTypes() {
      DataService.getProofOfAddressTypes().then((response) => {
        this.poatypes = response.data;
      });
    },
    setEmploymentStatuses() {
      DataService.getEmploymentStatuses().then((response) => {
        this.empstatuses = response.data;
      });
    },
    setSourceOfFundsTypes() {
      DataService.getSourceOfFundsTypes().then((response) => {
        this.softypes = response.data;
      });
    },
  },
});
