<template>
  <div v-if="rendered">
    <div v-if="currentRouteName === 'TradeContinuation'">
      <h2
        class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-10 text-shiftlydarkblue"
      >
        Get Ready for Trading in {{ this.targetYear }}
      </h2>
      <h3
        class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
      >
        We’re committed to ensuring your trading continues seamlessly next year.
        To streamline the process, kindly provide the following information.
      </h3>
      <VForm
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
        v-slot="{ errors, values }"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="py-10 flex flex-col">
          <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
            <div
              class="block bg-gradient-to-r from-shiftlyblue to-shiftlylightblue text-white h-11 shadow-md rounded-t-lg"
            >
              <p class="p-2.5 text-white font-bold">Trade Information</p>
            </div>
            <div class="rounded-lg shadow-md mx-4 pb-6 md:pb-3">
              <div class="mx-3 mt-5 sm:flex">
                <div class="md:w-6/12 px-2 mb-2.5 text-left">
                  <label
                    class="uppercase tracking-wide text-shiftlydarkblue text-xs font-bold mb-2"
                    >Trade Size</label
                  >
                </div>
              </div>
              <div class="mx-3 sm:flex">
                <span class="px-2 mb-2.5 text-left text-shiftlydarkblue"
                  >Please enter the Rand amount you'll be trading with at a
                  time. To optimise the AIT auto approvals, we recommend using
                  amounts that evenly divide into R1 million, such as R200k,
                  R250k, R333k, or R500k. <br /><br />
                  You can increase, decrease, or maintain your current trade
                  size. If you choose to increase it, we will request additional
                  source of funds documentation to confirm that you have this
                  amount available.
                </span>
              </div>
              <div class="mx-3 md:flex">
                <div class="md:w-6/12 px-2 mb-2.5 text-left">
                  <label
                    class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                    >Trade Size
                    <VuePopper
                      placement="top"
                      offsetDistance="4"
                      hover
                      content="Enter the Rand amount of your own funds you’ll be using to trade with at a time."
                      class="normal-case"
                    >
                      <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                    ></VuePopper>
                  </label>
                  <VField name="own_funds" v-slot="{ field }">
                    <input
                      v-number="own_funds_number"
                      v-bind="field"
                      class="zar w-full bg-white text-shiftlydarkblue border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                      :class="{
                        'border-red-500': errors.own_funds,
                      }"
                  /></VField>
                  <div class="text-red-500 text-xs italic">
                    {{ errors.own_funds }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="values.service_level === 'premium'">
              <div class="rounded-lg shadow-md mx-4 pb-6 md:pb-3">
                <div class="mx-3 mt-5 sm:flex">
                  <div class="md:w-6/12 px-2 mb-2.5 text-left">
                    <label
                      class="uppercase tracking-wide text-shiftlydarkblue text-xs font-bold mb-2"
                      >Capital Withdrawn</label
                    >
                  </div>
                </div>
                <div class="mx-3 sm:flex">
                  <span class="px-2 mb-2.5 text-left text-shiftlydarkblue"
                    >Have you already withdrawn, or do you plan to withdraw, any
                    portion of your trading capital once your trading is
                    complete? If you have only withdrawn or plan to withdraw
                    profits, please select 'No' for this question.
                  </span>
                </div>
                <div class="mx-3 md:flex">
                  <div class="md:w-12/12 px-2 mb-3.5 text-left">
                    <label
                      class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                      >Capital Withdrawn
                    </label>
                    <div class="form-check">
                      <VField
                        name="moved_funds"
                        type="radio"
                        value="yes"
                        class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlydarkblue checked:bg-shiftlydarkblue checked:border-shiftlydarkblue focus:ring-1 focus:ring-shiftlydarkblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        :class="{ 'border-red-500': errors.moved_funds }"
                      />
                      <span class="tracking-wide text-shiftlyblue text-sm"
                        >Yes
                      </span>
                    </div>
                    <div class="form-check">
                      <VField
                        name="moved_funds"
                        type="radio"
                        value="no"
                        class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlydarkblue checked:bg-shiftlydarkblue checked:border-shiftlydarkblue focus:ring-1 focus:ring-shiftlydarkblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        :class="{ 'border-red-500': errors.moved_funds }"
                      />
                      <span class="tracking-wide text-shiftlyblue text-sm"
                        >No
                      </span>
                    </div>
                    <div class="text-red-500 text-xs italic">
                      {{ errors.moved_funds }}
                    </div>
                    <p class="text-sm text-gray-600 mt-1">
                      Please be advised that failure to answer this question
                      accurately will result in your trading not continuing
                      seamlessly.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded-lg shadow-md mx-4 pb-6 md:pb-3">
              <div class="mx-3 mt-5 sm:flex">
                <div class="md:w-6/12 px-2 mb-2.5 text-left">
                  <label
                    class="uppercase tracking-wide text-shiftlydarkblue text-xs font-bold mb-2"
                    >Allowance Information</label
                  >
                </div>
              </div>
              <div class="mx-3 md:flex">
                <span class="px-2 mb-2.5 text-left text-shiftlydarkblue">
                  Trading through Shiftly will utilise your foreign capital
                  allowances. You're allowed to send up to R1m on your Single
                  Discretionary Allowance (SDA) and R10m on your Foreign
                  Investment Allowance (FIA) abroad per calendar year.
                  <br /><br />
                  In the SDA and FIA fields below, please enter the amount of
                  each you'd like to utilise for arbitrage trading for the
                  {{ this.targetYear }} calendar year.
                </span>
              </div>
              <div class="mx-3 mt-0.5 md:flex">
                <div class="md:w-6/12 px-2 mb-2.5 text-left">
                  <label
                    class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                    >SDA
                    <VuePopper
                      placement="top"
                      offsetDistance="4"
                      hover
                      content="Total amount of SDA that you would like to utilise (max 1,000,000)."
                      class="normal-case"
                    >
                      <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                    ></VuePopper>
                  </label>
                  <VField name="sda" v-slot="{ field }">
                    <input
                      v-number="sda_amount_number"
                      v-bind="field"
                      :disabled="
                        sdaSuccess && this.client.status !== 'registered'
                      "
                      class="w-full bg-white text-shiftlydarkblue border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                      :class="{
                        'border-red-500': errors.sda,
                        'opacity-70 cursor-not-allowed':
                          sdaSuccess && this.client.status !== 'registered',
                      }"
                  /></VField>
                  <div class="text-red-500 text-xs italic">
                    {{ errors.sda }}
                  </div>
                </div>
                <div class="md:w-6/12 px-2 mb-2.5 text-left">
                  <label
                    class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                    >FIA
                    <VuePopper
                      placement="top"
                      offsetDistance="4"
                      hover
                      content="Total amount of FIA that you would like to utilise (max 10,000,000)."
                      class="normal-case"
                    >
                      <i class="fa-solid fa-circle-info text-shiftlyorange"></i
                    ></VuePopper>
                  </label>
                  <VField name="fia" v-slot="{ field }">
                    <input
                      v-number="fia_amount_number"
                      v-bind="field"
                      :disabled="
                        fiaSuccess && this.client.status !== 'registered'
                      "
                      class="w-full bg-white text-shiftlydarkblue border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                      :class="{
                        'border-red-500': errors.fia,
                        'opacity-70 cursor-not-allowed':
                          fiaSuccess && this.client.status !== 'registered',
                      }"
                  /></VField>
                  <div class="text-red-500 text-xs italic">
                    {{ errors.fia }}
                  </div>
                </div>
              </div>
              <div class="mx-3 mt-1 md:flex">
                <span class="px-2 mb-2.5 text-left text-shiftlydarkblue">
                  When specifying how much of your 2025 SDA and/or FIA you wish
                  to allocate for arbitrage trading through Shiftly, remember to
                  account for other planned uses of these allowances. Buying
                  items from overseas or spending money abroad while traveling
                  counts toward your SDA, and sending funds abroad for foreign
                  investments counts toward your FIA. Ensure you reserve
                  sufficient allowances for these purposes to avoid exceeding
                  your limits.
                </span>
              </div>
            </div>
            <div class="mx-3 flex mt-2">
              <div class="w-6/12">
                <router-link to="/home">
                  <button
                    :disabled="isLoading"
                    type="button"
                    class="w-28 py-4 px-8 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  >
                    Cancel
                  </button>
                </router-link>
              </div>
              <div class="w-6/12">
                <button
                  :disabled="isLoading"
                  type="submit"
                  class="w-28 py-4 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  :class="[isLoading ? 'px-1' : 'px-8']"
                >
                  <span v-if="isLoading">
                    <i class="fa-solid fa-spinner animate-spin"></i>
                    Submitting
                  </span>
                  <span v-else>Submit</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
          >
            <div
              class="block bg-gradient-to-r from-shiftlyblue to-shiftlylightblue text-white h-11 shadow-md rounded-t-lg"
            >
              <p class="p-2.5 text-white font-bold">
                Historical Allowance Information
              </p>
            </div>
            <div class="mx-3 mt-5 md:flex">
              <span class="px-2 mb-2.5 text-left text-shiftlydarkblue">
                Please see your historical allowances displayed below.
              </span>
            </div>
            <div class="mx-auto w-11/12">
              <div class="py-2 inline-block min-w-full">
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                      >
                        Year
                      </th>
                      <th
                        scope="col"
                        class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                      >
                        SDA
                      </th>
                      <th
                        scope="col"
                        class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                      >
                        FIA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.clientAllowances"
                      v-bind:key="index"
                      class="rounded-none shadow-none p-1"
                    >
                      <td
                        data-label="Year"
                        class="w-1/5 border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                      >
                        <span
                          class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full bg-white text-shiftlydarkblue"
                          >{{ item.year }}</span
                        >
                      </td>
                      <td
                        data-label="SDA"
                        class="w-2/5 border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                      >
                        <span
                          class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full bg-white text-shiftlydarkblue"
                          >{{ item.sda }}</span
                        >
                      </td>
                      <td
                        data-label="FIA"
                        class="w-2/5 border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                      >
                        <span
                          class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full bg-white text-shiftlydarkblue"
                          >{{ item.fia }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import { directive as VNumber } from "@coders-tm/vue-number-format";
import * as Sentry from "@sentry/vue";

export default {
  name: "TradeContinuation",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      own_funds: Yup.string().test("infoAmount", null, (own_funds) => {
        if (own_funds.length > 0) {
          if (!isNaN(Number(own_funds.replace(",", "")))) {
            own_funds = Number(own_funds.replace(",", ""));
          } else {
            var newOwnFunds = own_funds.replace(",", "");
            if (!isNaN(Number(newOwnFunds.replace(",", "")))) {
              own_funds = Number(newOwnFunds.replace(",", ""));
            }
          }
          if (own_funds < 200000) {
            return new Yup.ValidationError(
              "Trade Size cannot be less than R200,000.",
              null,
              "own_funds"
            );
          } else if (own_funds > 1000000) {
            return new Yup.ValidationError(
              "Trade Size cannot be more than R1,000,000.",
              null,
              "own_funds"
            );
          } else {
            return true;
          }
        } else {
          return new Yup.ValidationError(
            "Trade Size is required",
            null,
            "own_funds"
          );
        }
      }),
      moved_funds: Yup.string().when("service_level", {
        is: (service_level) => service_level === "premium",
        then: Yup.string().required("Please indicate Yes or No"),
      }),
      sda: Yup.string().required("SDA Amount is required"),
      fia: Yup.string().required("FIA Amount is required"),
      service_level: Yup.string(),
    });
    const formValues = {
      own_funds: 0,
      moved_funds: "",
      sda: 0,
      fia: 0,
      service_level: "",
    };
    return {
      schema,
      formValues,
      rendered: false,
      sdaSuccess: false,
      fiaSuccess: false,
      isLoading: false,
      patchUrl: "",
      tradeInfo: {
        credit_amount: 0,
        own_funds: 0,
        trading_status: "",
        year: "",
      },
      client: [],
      sda_amount_number: {
        separator: ",",
        precision: 2,
        min: 0,
        max: 1000000,
        masked: false,
      },
      fia_amount_number: {
        separator: ",",
        precision: 2,
        min: 0,
        max: 10000000,
        masked: false,
      },
      own_funds_number: {
        separator: ",",
        precision: 2,
        min: 0,
        max: 10000000,
        masked: false,
      },
      specifiedDate: this.createSpecifiedDate(),
      currentDate: new Date(),
      targetYear: "",
      clientAllowances: [],
    };
  },
  directives: {
    number: VNumber,
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client.service_level === "") {
        this.$router.push("/home");
      }
      if (this.client !== "") {
        var currentDateYear = this.currentDate.getFullYear();
        this.targetYear =
          this.currentDate < this.specifiedDate
            ? currentDateYear
            : currentDateYear + 1;

        this.formValues.service_level = this.client.service_level;
        this.formValues.own_funds = this.client.own_funds;
        this.tradeInfo.own_funds = this.client.own_funds;

        ClientService.getTradeParameters().then((tradeparameters) => {
          if (tradeparameters.data.count > 0) {
            Object.entries(tradeparameters.data.results).forEach(
              ([, value]) => {
                if (value.year === this.targetYear) {
                  this.patchUrl = value.url;
                }
              }
            );
          }
        });

        ClientService.getAllowancesOrdering("created_at").then((allowances) => {
          if (allowances.data.count > 0) {
            this.clientAllowances = allowances.data.results;
            Object.entries(allowances.data.results).forEach(([, value]) => {
              if (value.year === this.targetYear) {
                this.$router.push("/home");
              }
            });
          }
          this.rendered = true;
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  created() {
    document.title = "Shiftly Client Portal - Trade Continuation";
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useApiStore, [
      "patchTradeParameterInformation",
      "postAllowanceInformation",
      "postZohoSignRequest",
    ]),
    createSpecifiedDate() {
      var specifiedDate = new Date();
      specifiedDate.setDate(12);
      specifiedDate.setMonth(11);
      specifiedDate.setHours(0, 0, 1);
      return specifiedDate;
    },
    showConfirm(values, actions) {
      let text =
        `Once submitted, you won’t be able to change these values. Please ensure they are correct before proceeding.
          <br><br>
          By clicking "Submit," I acknowledge that I will receive a ZohoSign envelope via email, which must be signed to ensure my trading continues in ` +
        this.targetYear;

      this.$swal({
        title: "Are you sure?",
        html: text,
        icon: "warning",
        iconColor: "#ffbb11",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Back",
        confirmButtonText: "Submit",
        reverseButtons: "true",
      }).then((result) => {
        if (result.isConfirmed) {
          this.patchToAPI(values, actions);
        } else if (result.isDismissed) {
          this.isLoading = false;
        }
      });
    },
    onSubmit(values, actions) {
      this.isLoading = true;

      //setting trading_status
      if (values.moved_funds === "yes") {
        this.tradeInfo.trading_status = "inactive_funds_withdrawn";
      } else {
        this.tradeInfo.trading_status = "inactive_awaiting_commencement";
      }

      //parsing own_funds to number
      if (isNaN(values.own_funds)) {
        if (!isNaN(Number(values.own_funds.replace(",", "")))) {
          this.tradeInfo.own_funds = Number(values.own_funds.replace(",", ""));
        } else {
          var newFunds = values.own_funds.replace(",", "");
          if (!isNaN(Number(newFunds.replace(",", "")))) {
            this.tradeInfo.own_funds = Number(newFunds.replace(",", ""));
          }
        }
      }

      //parsing sda amount to number
      if (isNaN(values.sda)) {
        if (!isNaN(Number(values.sda.replace(",", "")))) {
          values.sda = Number(values.sda.replace(",", ""));
        } else {
          var newSDAAmount = values.sda.replace(",", "");
          if (!isNaN(Number(newSDAAmount.replace(",", "")))) {
            values.sda = Number(newSDAAmount.replace(",", ""));
          }
        }
      }

      //parsing fia amount to number
      if (isNaN(values.fia)) {
        if (!isNaN(Number(values.fia.replace(",", "")))) {
          values.fia = Number(values.fia.replace(",", ""));
        } else {
          var newFIAAmount = values.fia.replace(",", "");
          if (!isNaN(Number(newFIAAmount.replace(",", "")))) {
            values.fia = Number(newFIAAmount.replace(",", ""));
          }
        }
      }

      //adding year to payloads
      if (this.currentDate < this.specifiedDate) {
        values.year = new Date().getFullYear();
        this.tradeInfo.year = values.year;
      } else {
        values.year = new Date().getFullYear() + 1;
        this.tradeInfo.year = values.year;
      }

      //Removing file objects from payload
      delete values.own_funds;
      delete values.moved_funds;
      delete values.service_level;

      this.showConfirm(values, actions);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    showSuccess() {
      this.$swal(
        "Success!",
        "Your trade information has been submitted successfully.",
        "success"
      );
    },
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance.",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    // Patch Trade Size
    patchToAPI(values, actions) {
      //Sending payload to the backend
      var payload = { url: this.patchUrl, values: this.tradeInfo };

      this.patchTradeParameterInformation(payload).then(
        () => {
          this.postToAPI(values, actions);
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
    //Post Allowance Information
    postToAPI(values, actions) {
      //Sending payload to the backend
      var payload = { values: values };

      this.postAllowanceInformation(payload).then(
        () => {
          this.callZohoSignRequest();
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
    // Send Document Pack
    callZohoSignRequest() {
      if (this.client.status === "onboarded") {
        this.postZohoSignRequest(this.client.url).then(
          () => {
            this.isLoading = false;
            this.showSuccess();
            this.$router.push("/home");
          },
          (error) => {
            this.isLoading = false;
            this.showError(error);
          }
        );
      } else {
        this.isLoading = false;
        this.showSuccess();
        this.$router.push("/home");
      }
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #fdb71a;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fdb71a;
}

@media screen and (max-width: 1023px) {
  #card table {
    border: 0;
  }

  #card table thead {
    display: none;
  }

  #card table tr {
    margin-bottom: 10px;
    display: block;
  }

  #card table td {
    display: block;
    text-align: right;
  }

  #card table td:last-child {
    border-bottom: 0;
  }

  #card table td::before {
    content: attr(data-label);
    float: left;
    margin-top: 13px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
