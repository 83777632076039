<template>
  <div v-if="rendered">
    <div v-if="currentRouteName === 'Referrals'">
      <h2
        class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-10 text-shiftlydarkblue"
      >
        Referrals
      </h2>
      <h3
        class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
      >
        Shiftly's referral program lets you reduce your forex conversion fee.
        When you refer a new or existing Shiftly client (who isn't already
        Premium) that signs up for our Premium service and starts trading,
        you'll get a 0.05% fee reduction.
        <br /><br />
        Your fee can drop to as low as 0% if you refer four clients who are
        actively trading on our Premium service. There's no limit to the number
        of clients you can refer.
      </h3>
      <div class="py-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
          <div
            class="lg:flex flex-wrap py-5 justify-center"
            style="cursor: auto"
          >
            <div
              class="relative py-6 border-2 border-gray-200 rounded-lg h-auto lg:w-5/12 lg:m-4 mx-4 mb-4 mt-8"
            >
              <h2 class="font-bold text-lg mb-4 text-shiftlydarkblue">
                Referral ID
              </h2>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="my-3">
                <p class="text-shiftlyblue col-span-3 text-center text-lg">
                  {{ this.client.referral_id }}
                </p>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
            </div>
            <div
              class="relative py-6 border-2 border-gray-200 rounded-lg h-auto lg:w-5/12 m-4"
            >
              <h2 class="font-bold text-lg mb-4 text-shiftlydarkblue">
                Current Fee
              </h2>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
              <div class="my-3">
                <p class="text-shiftlyblue col-span-3 text-center text-lg">
                  {{ this.calculateCurrentFee() }}%
                </p>
              </div>
              <hr
                class="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-shiftlyblue to-transparent opacity-50"
              />
            </div>
          </div>
        </div>
      </div>
      <VForm
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
        v-slot="{ errors }"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="pb-10 flex flex-col">
          <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
            <div class="mx-3 mt-5 sm:flex">
              <div class="md:w-6/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlydarkblue text-xs font-bold mb-2"
                  >Send Referral Link</label
                >
              </div>
            </div>
            <div class="mx-3 sm:flex">
              <span class="px-2 mb-2.5 text-left text-shiftlydarkblue">
                Please enter the email address of the person you'd like to send
                your referral link to.
              </span>
            </div>
            <div class="mx-3 md:flex">
              <div class="md:w-full px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Email</label
                >
                <div class="flex">
                  <VField
                    name="email"
                    type="text"
                    class="w-full h-11 bg-white text-shiftlydarkblue border border-gray-200 rounded py-2 px-3 mb-4 mr-4 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                    :class="{ 'border-red-500': errors.email }"
                  />
                  <button
                    :disabled="isLoading"
                    type="submit"
                    class="w-28 h-11 py-2 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                    :class="[isLoading ? 'px-1' : 'px-8']"
                  >
                    <span v-if="isLoading">
                      <i class="fa-solid fa-spinner animate-spin"></i>
                      Sending
                    </span>
                    <span v-else>Send</span>
                  </button>
                </div>
                <div class="text-red-500 text-xs italic">
                  {{ errors.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </VForm>
      <div v-if="showReferredClients" class="pb-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlylightblue text-white h-11 rounded-t-lg shadow-md"
          >
            <p class="p-2.5 text-white font-bold">Referred Clients</p>
          </div>
          <div class="mx-auto w-11/12">
            <div class="py-2 inline-block min-w-full">
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="border-b uppercase text-shiftlyblue text-xs font-bold p-2"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="border-b uppercase text-shiftlyblue text-xs font-bold p-2"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in this.referredClients"
                    v-bind:key="client.primary_email"
                    class="rounded-none shadow-none p-1"
                  >
                    <td
                      data-label="Email"
                      class="w-3/5 border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                    >
                      <span
                        class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full bg-white text-shiftlydarkblue"
                        >{{ client.primary_email }}</span
                      >
                    </td>
                    <td
                      data-label="Status"
                      class="w-2/5 border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                    >
                      <div class="w-full bg-gray-200 rounded-md">
                        <div
                          v-if="client.status == 'invited'"
                          class="w-1/5 bg-shiftlylightblue p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          20%
                        </div>
                        <div
                          v-if="client.status == 'registered'"
                          class="w-2/5 bg-shiftlylightblue p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          40%
                        </div>
                        <div
                          v-if="client.status == 'signed_up'"
                          class="w-3/5 bg-shiftlylightblue p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          60%
                        </div>
                        <div
                          v-if="
                            client.status == 'onboarded' &&
                            (client.trading_status ==
                              'active_submitting_aits' ||
                              client.trading_status ==
                                'active_not_submitting_aits' ||
                              client.trading_status ==
                                'inactive_trading_complete')
                          "
                          class="w-5/5 bg-shiftlylightblue p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          100%
                        </div>
                        <div
                          v-if="
                            client.status == 'onboarded' &&
                            (client.trading_status ==
                              'inactive_tax_non_compliant' ||
                              client.trading_status ==
                                'inactive_trading_ceased' ||
                              client.trading_status ==
                                'inactive_awaiting_commencement' ||
                              client.trading_status ==
                                'inactive_funds_withdrawn' ||
                              client.trading_status ==
                                'inactive_trade_size_increase' ||
                              client.trading_status ==
                                'inactive_insufficient_funds')
                          "
                          class="w-4/5 bg-shiftlylightblue p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          80%
                        </div>
                        <div
                          v-if="client.status == 'archived'"
                          class="w-0/5 bg-gray-200 p-0.5 text-center text-xs font-medium text-white rounded-md"
                        >
                          0%
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mx-auto w-11/12 text-left">
            <div
              class="p-5 border-2 border-gray-200 rounded-lg h-auto w-full mt-4 mb-6"
            >
              <div class="flex">
                <span class="text-shiftlylightblue mr-6">0%</span>
                <span class="text-shiftlydarkblue"
                  >The client has chosen not to complete their application and
                  has been archived.</span
                >
              </div>
              <div class="flex">
                <span class="text-shiftlylightblue mr-4">20%</span>
                <span class="text-shiftlydarkblue"
                  >The client has received an email containing the referral
                  link.</span
                >
              </div>
              <div class="flex">
                <span class="text-shiftlylightblue mr-4">40%</span>
                <span class="text-shiftlydarkblue"
                  >The client has registered an account but has yet to complete
                  their trading profile.</span
                >
              </div>
              <div class="flex">
                <span class="text-shiftlylightblue mr-4">60%</span>
                <span class="text-shiftlydarkblue"
                  >The client has submitted all the required information, and we
                  are busy processing their application.</span
                >
              </div>
              <div class="flex">
                <span class="text-shiftlylightblue mr-4">80%</span>
                <span class="text-shiftlydarkblue"
                  >The client is onboarded but not actively trading due to
                  various reasons.</span
                >
              </div>
              <div class="flex">
                <span class="text-shiftlylightblue mr-2">100%</span>
                <span class="text-shiftlydarkblue"
                  >The client is trading and active and counts towards your
                  reduced fee.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view></router-view>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "ShiftlyReferrals",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email()
        .max(255, "Email cannot be longer than 255 characters.")
        .required("Email is required."),
    });
    const formValues = {
      email: "",
    };
    return {
      schema,
      formValues,
      rendered: false,
      isLoading: false,
      client: [],
      referredClients: [],
      showReferredClients: false,
      fullUserName: localStorage.getItem("fullUserName"),
    };
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client !== "") {
        if (
          this.client.referral_active === false ||
          this.client.status !== "onboarded"
        ) {
          this.$router.push("/home");
        }
        ClientService.getClientReferrals(url).then((referrals) => {
          if (referrals.data.length > 0) {
            this.referredClients = referrals.data;
            this.showReferredClients = true;
          }
          if (this.client.referral_invites.length > 0) {
            for (var email of this.client.referral_invites) {
              const checkEmail = (obj) => obj.primary_email === email;
              if (!this.referredClients.some(checkEmail)) {
                this.referredClients.push({
                  primary_email: email,
                  status: "invited",
                  trading_status: "inactive_awaiting_commencement",
                });
              }
            }
            this.showReferredClients = true;
            this.rendered = true;
          } else {
            this.rendered = true;
          }
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  created() {
    document.title = "Shiftly Client Portal - Referrals";
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useApiStore, ["postReferralInvite"]),
    calculateCurrentFee() {
      return parseFloat(this.client.conversion_fee).toFixed(2);
    },
    onSubmit(values, actions) {
      this.isLoading = true;
      this.postToAPI(values, actions);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance.",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    //Api Submit
    postToAPI(values, actions) {
      //sending payload to the backend
      var payload = { url: this.client.url, values: values };

      this.postReferralInvite(payload).then(
        () => {
          this.isLoading = false;
          this.$swal({
            title: "Success!",
            text: "Your referral link has been sent successfully.",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.go(0);
            }
          });
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
