import { useApiStore } from "../stores/apiStore";
import axiosInstance from "./api";
import TokenService from "./token.service";
import EventBus from "../common/EventBus";

const setup = (pinia) => {
  const apiStore = useApiStore(pinia);
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = TokenService.getLocalAccessToken();
      const publicCalls = [
        "/v1/shiftly/banks/",
        "/v1/shiftly/countries/",
        "/v1/shiftly/nationalities/",
        "/v1/shiftly/holidays/",
        "/v1/shiftly/choices/race/",
        "/v1/shiftly/choices/saprovince/",
        "/v1/shiftly/choices/title/",
        "/v1/clients/choices/employmentstatus/",
        "/v1/clients/choices/documentcategory/",
        "/v1/clients/choices/proofofaddress/",
        "/v1/clients/choices/proofofidentity/",
        "/v1/clients/choices/sourceoffunds/",
        "/v1/clients/choices/maritalstatus/",
        "/v1/clients/choices/networth/",
      ];
      if (accessToken && !publicCalls.includes(config.url)) {
        config.headers["Authorization"] = "Bearer " + accessToken; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      if (res.config._retry) {
        EventBus.dispatch("reload");
      }
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/auth/jwt/create/" && err.response) {
        // Access Token was expired
        if (
          err.response.status == "403" &&
          err.response.data.code == "token_not_valid" &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            await axiosInstance
              .post("/auth/jwt/refresh/", {
                refresh: TokenService.getLocalRefreshToken(),
              })
              .then(
                (rs) => {
                  const accessToken = rs.data;
                  apiStore.refreshToken(accessToken);
                  TokenService.updateLocalAccessToken(accessToken);
                  return axiosInstance(originalConfig);
                },
                (_err) => {
                  apiStore.logout();
                  return Promise.reject(_err);
                }
              );
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
