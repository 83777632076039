import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";

const pinia = createPinia();

//Vue Router
import router from "./router";

//Sentry
import * as Sentry from "@sentry/vue";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

//Interceptors
import setupInterceptors from "./services/setupInterceptors";
setupInterceptors(pinia);

//Vue tel input
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

//tailwind elements
import "tw-elements";

//Tailwind CSS
import "./index.css";

//vue select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

//vue sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//vue 3 popper
import Popper from "vue3-popper";
import "./assets/popper.css";

//Vue Cookies
import VueCookies from "vue-cookies";

const options = {
  color: "#212957",
  confirmButtonColor: "#0447e0",
  cancelButtonColor: "#ff7674",
};

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://8d13e2842bd8461287a98dcbcc380367@o514353.ingest.sentry.io/4505345479213056",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["*"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
    new ExtraErrorDataIntegration({
      depth: 4,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  trackComponents: true,
  attachProps: true,
  attachStacktrace: true,
  beforeSend(event) {
    if (import.meta.env.PROD) {
      return event;
    }
  },
});

app.use(pinia);
app.use(router);
app.use(VueSweetalert2, options);
app.use(VueTelInput);
app.use(VueCookies, { expires: "30d" });
app.component("v-select", vSelect);
app.component("VuePopper", Popper);

if (import.meta.env.DEV) {
  app.config.globalProperties.$sfxEnabled = true;
} else {
  app.config.globalProperties.$sfxEnabled = false;
}

app.config.globalProperties.$baseURL = import.meta.env.VITE_BACKEND_API_URL;
app.config.globalProperties.$creditEnabled =
  import.meta.env.VITE_CREDIT_ENABLED;

app.mount("#app");
