class TokenService {
  getLocalRefreshToken() {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.refresh;
  }
  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.access;
  }
  updateLocalAccessToken(accessToken) {
    let token = JSON.parse(localStorage.getItem("token"));
    token.access = accessToken.access;
    localStorage.setItem("token", JSON.stringify(token));
  }
  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }
  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }
  removeToken() {
    localStorage.removeItem("token");
  }
  setUsername(fullUserName, shortUserName) {
    localStorage.setItem("fullUserName", fullUserName);
    localStorage.setItem("shortUserName", shortUserName);
  }
  removeUserName() {
    localStorage.removeItem("fullUserName");
    localStorage.removeItem("shortUserName");
  }
  setClientStatus(status) {
    localStorage.setItem("clientStatus", status);
  }
  removeClientStatus() {
    localStorage.removeItem("clientStatus");
  }
  setServiceLevel(serviceLevel) {
    localStorage.setItem("serviceLevel", serviceLevel);
  }
  removeServiceLevel() {
    localStorage.removeItem("serviceLevel");
  }
  setCreditChoice(creditChoice) {
    localStorage.setItem("creditChoice", creditChoice);
  }
  removeCreditChoice() {
    localStorage.removeItem("creditChoice");
  }
  setReferralActive(referralActive) {
    localStorage.setItem("referralActive", referralActive);
  }
  removeReferralActive() {
    localStorage.removeItem("referralActive");
  }
  getClientUrl() {
    return JSON.parse(localStorage.getItem("clientUrl"));
  }
  setClientUrl(clientUrl) {
    localStorage.setItem("clientUrl", JSON.stringify(clientUrl.client));
  }
  removeClientUrl() {
    localStorage.removeItem("clientUrl");
  }
}
export default new TokenService();
